<template>
  <div @click="isShow = false" id="personage">
    <div class="personageItem">
      <div class="title">
        <p>
          <span class="backG">获奖情况</span>
          <Button v-throttleClick class="add" @click="editItem(newList)">添加&nbsp;<Icon type="md-add" /></Button>
          <span v-if="newList" class="xiaoshou" :style="newList.auditStatus | colored">{{newList.auditStatus | type}}</span>
          <Icon v-if="newList && newList.auditStatus == 2" @click.stop="isShow = !isShow" class="yanjing" type="md-eye" size="16" />
          <span @click.stop="isShow = true" v-show="isShow" class="cause">
            <span style="display:block;textAlign: left; color:#f32048">未通过原因：</span>
            <span v-if="newList">{{newList.reason}}</span>
          </span> 
        </p>
      </div>
      <ul class="list">
        <li class="listItem">
          <div class="itemBox">
            <p>
              <span v-if="newList" class="school">{{ newList.content }}</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <Modal
      :scrollable="true"
      v-model="alert"
      width="572px"
      class-name="vertical-center-modal"
      :footer-hide="true"
    >
      <div class="alert">
        <template>
          <div class="title">获奖情况</div>
        <textarea maxlength="2000" style="fontSize:15px" v-model="newValue" placeholder='请输入获奖情况 ' class="alert1" name="message" rows="10" cols="61">
        
        </textarea>
        </template>

        <div class="btn">
          <div @click="closeAlert" class="close">取消</div>
          <Button style="border: none;outline: none;" v-throttleClick @click="affirm" class="affirm">提交</Button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "scholarship",
  props: ["sysUserPrize"],
  data(){
    return{
      newValue:'', //个人履历内容
      newList:{}, //获奖情况数据列表
      id:'', //id
      alert:false, //编辑框显影
      isShow:false,
      timing:false
    }
  },
  filters: {
    type(num) {
      if (num == 0) return `审核中`;
      if (num == 1) return `通过`;
      if (num == 2) return `不通过`;
    },
    colored(num) {
      if (num == 2) return "color:#fff;background:#F32048";
      if (num == 0) return "color:#fff;background:#f1c40f";
      if (num == 1) return "color:#fff;background:#2ecc71";
    },
  },
  methods:{
    // 编辑按钮
    editItem(item) {
      this.timing = true
      this.newValue = ''
      this.id = ''
      if (item !== undefined && item.id && item.auditStatus && !(item.auditStatus == 1 || item.auditStatus == 2)) {
        this.$Message.error("信息审核中");
        this.alert = false;
        return;
      }else if( item !== undefined && item.auditStatus && (item.auditStatus == 2 || item.auditStatus == 1) && item.auditStatus !== 0){
        this.newValue = item.content
        this.id = item.id
        this.alert = true
      }else{
        this.alert = true
      }
    },
    // 取消
    closeAlert(){
      this.alert = false
    },
    // 请求封装
    async regulation(obj){
      let {newValue} = this
      console.log('请求参数:',obj)
      const f1 = /\s\S+|S+\s|\S/;//空白符和字符串，字符串
      let f2 = /<(?:[^"'>]|(["'])[^"']*\1)*>/g; //匹配HTML标签
      if (!f1.test(newValue) && !this.id) {
        this.$Message.error('不能为空')
        return
      }
      if (f2.test(newValue) && !this.id){
        this.$Message.error('不合法')
        return
      }
      let res = await this.$apis.userServe.addOrEditPrize(obj);
      await this.getPrizeInfo()
      if(res.status == 200){
        this.$Message.success('提交成功')
        this.alert = false
      }else{
        this.$Message.error(res.resultDesc)
      }
    },
    // 确定
    async affirm(){
      if(!this.id){
        console.log('添加')
        let obj = {
          content : this.newValue
        }
        this.regulation(obj)
      }else if(this.id && this.newValue){
        console.log('修改')
        let obj = {
          content : this.newValue,
          id:this.id
        }
        this.regulation(obj)
      }
      if(!this.newValue && this.newList){
        let obj = {
          content : this.newValue,
          id : this.id
        }
        this.regulation(obj)
        console.log('删除')
      }
    },
    // 获取获奖情况
    async getPrizeInfo(){
      let res = await this.$apis.userServe.getPrizeInfo();
      this.newList = res.data
      console.log(res.data)
    }
  },
  created() {
    this.getPrizeInfo()
  },
};
</script>

<style lang="scss" scoped>
.alert {
  .alert1{
    margin-top: 25px;
    margin-left: 15px;
  }
  .btn {
    margin-top: 34px;
    width: 100%;
    // height: 120px;
    //background: pink;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      text-align: center;
      line-height: 38px;
      width: 72px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      margin-right: 13px;
      cursor: pointer;
    }

    .affirm {
      margin-left: 13px;
      text-align: center;
      line-height: 38px;
      width: 70px;
      height: 36px;
      color: #fff;
      background: #007fff;
      border-radius: 8px;
      cursor: pointer;
    }
  }

  .title {
    margin-left: 15px;
    margin-top: 24px;
    height: 25px;
    font-size: 24px;
    //background: yellow;
    font-family: Microsoft YaHei;
    font-weight: 900;
    color: #333333;
    line-height: 24px;
  }

  .titleItem {
    margin-left: 77px;
    margin-top: 50px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    height: 25px;
    font-size: 24px;
  }
}

#personage {
  .personageItem {
    width: 1200px;
    min-height: 150px;
    // padding-bottom: 32px;
    background: #ffffff;
    // box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
    // margin-top: 12px;
    padding:32px 0 1px 0;
    box-sizing: content-box;
    .list {
      margin-top: 28px;
      //background: #e5e5e5;
      // .listItem:hover {
      //   background: #e5e5e5;
      //   color: #333;
      //   opacity: 0.32;

      //   .edit {
      //     display: block;
      //     img {
      //       cursor: pointer;
      //     }
      //   }
      // }

      .listItem {
        color: #333;
        // padding-top: 23px;
        width: 1200px;
        // height: 100px;
        padding-left: 40px;
        //background: #fff;
        border-radius: 10px;
        position: relative;
        margin: 0 auto;
        .itemBox {
          margin-bottom: 10px;
          .school{
            max-width: 900px;
            white-space: pre-wrap	;
            line-height: 40px;
          }
          p > span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            display: inline-block;
          }
        }

        .edit {
          display: none;
          position: absolute;
          right: 64px;
          height: 100%;
          bottom: 0;
          background: #e5e5e5;
          padding-top: 12px;
          img {
            margin-left: 20px;
            width: 17px;
            height: 18px;
          }
        }
      }
    }

    .box {
      //display: flex;
    }

    .title {
      margin-left: 37px;
      height: 22px;
      .backG {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }

      .add {
        margin-left: 15px;
        // height: 22px;
        cursor: pointer;
        text-align: center;
        line-height: 100%;
        // line-height: 22px;
        //background: pink;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #00A4FF;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 4px 10px 4px 10px;
        border: 1px dashed #00A4FF;
        
      }
      .xiaoshou {
        margin-left: 20px;
        padding: 3px;
        border-radius: 5px;
        font-size: 13px;
      }
      .yanjing{
        cursor: pointer;
        margin-left: 10px;
        color: #F32048;
      }
      .cause{
        display: block;
        max-width: 400px;
        max-height: 150px;
        overflow: auto;
        background: #fff;
        position: relative;
        top: -5px;
        left: 235px;
        box-shadow: 0px 0px 5px #000000;
        z-index: 999;
        padding: 15px;
        word-wrap:break-word;
      }
      //margin-top: 32px;
    }
  }
}
</style>
